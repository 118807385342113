var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('searchBody',[_c('search',{staticClass:"searchbar",attrs:{"label-width":80,"searchs":_vm.searchs},on:{"search":_vm.onSearch,"reset":_vm.reset}}),_c('Card',{ref:"table_container",staticClass:"table_container"},[_c('Table',{staticClass:"table mt_10",attrs:{"stripe":"","columns":_vm.dataColumn,"data":_vm.dataList},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',{class:'now_state_' + row.status},[_vm._v(_vm._s(_vm.statusArr.find(function (info) { return info.id == row.status; }).title))])]}},{key:"see",fn:function(ref){
var row = ref.row;
return [_c('span',{class:'now_state_' + row.see},[_vm._v(_vm._s({ 0: '待查看', 1: '查看待确认', 2: '已确认' }[row.see] || row.see))])]}},{key:"sid",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s((_vm.serviceList.find(function (info) { return info.id == row.sid; }) || {}).title || row.sid)+" ")]}},{key:"accout_id",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s((_vm.employeeList.find(function (info) { return info.id == row.accout_id; }) || {}).nickname || row.accout_id)+" ")]}},{key:"manager",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"table_manager flex flex_wrap align_center"},[_c('Button',{attrs:{"type":"primary","size":"small","ghost":""},on:{"click":function($event){return _vm.onEdit(row.re_id,row.id)}}},[_vm._v("编辑")]),_c('Button',{attrs:{"type":"primary","size":"small","ghost":""},on:{"click":function($event){return _vm.onQuery(row.re_id,row.id)}}},[_vm._v("查看")]),_c('Button',{attrs:{"type":"success","size":"small","ghost":""},on:{"click":function($event){return _vm.onSend(row.re_order_no)}}},[_vm._v("发送报告")])],1)]}}])})],1),_c('div',{staticClass:"relative width_100_percent text_align_right"},[_c('Page',{attrs:{"size":"small","total":_vm.totalPageNum || 1,"page-size":1,"show-elevator":""},on:{"on-change":_vm.currentChange}})],1),_c('mySpin',{attrs:{"loading":_vm.ajaxLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }